@font-face {
  font-family: archivo;
  src: url(./assets/Font/OnlineWebFonts_COM_a33149cae44ea1303c624ca81af93b7f/Archivo-Expanded/Archivo-Expanded.ttf);
}
body {
  font-family: archivo, serif;
}
header {
  position: relative;
}
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
.navbar .navbar-nav .nav-link {
  color: #020407;
  
}
.navbar .btnM .getStarted {
  padding: 7px 16px;
  border-radius: 25px;
  border: 1px;
  border: 1px solid #010205;
  font-weight: bold;
  background: transparent;
}
.navbar .btnM .getStarted:hover {
  background: #010205;
  color: #fff;
}
.navbar .btnM .bell {
  background: #010205;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: none;
  margin-left: 10px;
}
  .navbar .btnM .bell img {
    width: 22px;
  }
  .headerMain {
    padding-top: 100px;
    padding-bottom: 50px;
    background: linear-gradient(280deg, #D4FF7A -2.6%, #DCFFC1 99.72%);
    overflow: hidden;
  }
  .headerMain h1 {
    /* font-family: Archivo Expanded; */
    font-size: 130px;
    font-weight: 800;
    line-height: 1;    
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  .headerMain .social1Main {
    background: #677659;
    transform: rotate(-5deg);
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
    top: 70px;
  }
  .headerMain .social1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerMain .social1 .link {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    padding: 12px;
    text-decoration: none;
  }
  .headerMain .social1 .socialIco {
    width: 38px;
  }
  .headerMain .social2Main {
    background: #3461FF;
    transform: rotate(5deg);
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
  }
  .headerMain .social2 {
    background: #3461FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerMain .social2 .link {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    padding: 12px;
    text-decoration: none;
  }
  .headerMain .logoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .headerMain .logoDiv img {
    width: 50px;
    margin: 0 10px ;
  }
  .profSumarySec {
    padding: 50px 0;
    background: #101828;
    color: #fff;
  }
  .profSumarySec h1 {
    font-size: 48px;
    max-width: 300px;
    font-weight: 800;
  }
  .profSumarySec .sumaryCard {
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: 0px;
    transition: all 0.3s;
  }
  .profSumarySec .sumaryCard:hover {
    transition: all 0.3s;
    top: -10px;
  }
  .ai-center {
    align-items: center;
  }
  .jc-center {
    justify-content: center;
  }
  .careerHighlights {
    padding: 50px 0;
  }

  .careerHighlights .executive {
    color: #000;
    font-size: 38px;
    font-weight: 800;
  }
  .careerHighlights p {
    color: #00000099;
  }
  .careerHighlights .executive span {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(134.29deg, #C59CB7 0%, #E28F81 100%) text;
  }
  .careerHighlights .imgMain {
    max-width: 320px;
  }
  .careerHighlights .imgMain.img1{
    margin-left: auto;
  }
  .careerHighlights .learnMore {
    background: #000000;
    /* font-size: 18px; */
    text-decoration: underline;
    color: #fff;
    border: 1px solid #000;
    border-radius: 25px;
    padding: 7px;
    width: 100%;
    margin-top: 20px;
    display: inline-block;
    text-align: center;
  }
  .mediaSec {
    padding: 50px 0;
  }
  .mediaSec .galery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mediaSec .galery img {
    width: 30%;
    margin-bottom: 20px;
  }
  .mediaSec .homeHeading {
    max-width: 550px;
    margin: auto;
    margin-bottom: 40px;
    
  }
  .mediaSec h2 {
    color: #000;
    font-size: 38px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .mediaSec h2 span {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(134.29deg, #C59CB7 0%, #E28F81 100%) text;
  }
  .mediaSec .learnMore {
    background: #000000;
    /* font-size: 18px; */
    text-decoration: underline;
    color: #fff;
    border: 1px solid #000;
    border-radius: 25px;
    padding: 7px;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    display: inline-block;
  }
  .mediaSec .content {
    max-width: 400px;
    margin-left: auto;
  }
  .mediaSec .desc {
    color: #00000099;
    display: flex;
    align-items: baseline;
  }
  .mediaSec .desc a {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(134.29deg, #C59CB7 0%, #E28F81 100%) text;
    text-decoration: underline;
  }
  .mediaSec .desc span {
    display: inline-block;
    background: linear-gradient(89.69deg, #C59CB7 -2.6%, #E28F81 99.72%);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .awardSec {
    background: #101828;
    border-radius: 40px;
    padding: 40px ;
    margin: 40px 0;
  }
  .awardSec h2 {
    color: #FFFFFF;
    font-size: 38px;
    margin-bottom: 20px;
    font-weight: 800;
  }
  .awardSec .desc {
    color: #FFFFFFC7;
    margin-bottom: 20px;
    max-width: 500px;
    text-transform: capitalize;
  }
  .awardSec .awardM {
    color: #fff;
  }
  .awardSec .awardM .name {
    color: #D4FF7A;
  }
  .awardSec .awardM .link {
    color: #fff;
    text-decoration: underline;
  }
  .d-flex {
    display: flex;
  }
  .eduSec {
    padding: 50px 0;
  }
  .homeHeading {
    font-size: 55px;
    text-align: center;
    margin-bottom: 40px;
    /* max-width: 500px; */
    margin: auto;
    margin-bottom: 30px;
    font-weight: 800;
  }
  .eduSec .degRow {
    display: flex;
    justify-content: space-between;
  }
  .eduSec .degRow .col1 {
    display: flex;
    padding: 10px;
  }
  .eduSec .degRow .col1 .number {
    background: linear-gradient(89.69deg, #D4FF7A -2.6%, #DCFFC1 99.72%);
    width: 70px;
    height: 70px;
    font-size: 32px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;

  }
  .eduSec .degRow .col1 .name {
    color: #000000;
    font-size: 32px;
    font-weight: bold;
  }
  .eduSec .degRow .col1 .inst {

  }
  .readyTo {
    background: linear-gradient(89.69deg, #D4FF7A -2.6%, #DCFFC1 99.72%);
    padding: 80px 40px;
    border-radius: 40px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
  }
  .readyTo h1 {
    font-size: 55px;
    font-weight: bold;
    font-weight: 800;
  }
  .readyTo .getStarted {
    background: #000000;
    /* font-size: 18px; */
    text-decoration: underline;
    color: #fff;
    border: 1px solid #000;
    border-radius: 25px;
    padding: 7px 20px;
    max-width: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }
  footer {
    background: #000000CC;
    padding: 30px 0;
    margin-top: 70px;
  }
  footer .footerIn {
    display: flex;
    align-items: center;
    color: #fff;
    flex-wrap: wrap;
  }
  
  footer .footerIn .privacy {
    margin-left: 50px;
  }
  footer .footerIn a {
    color: #fff;
    text-decoration: none;
  }
  .formModal .submitBtn {
    background: #000000;
    /* font-size: 18px; */
    color: #fff;
    border: 1px solid #000;
    border-radius: 25px;
    padding: 7px;
    width: 100%;
    margin-top: 20px;
    display: inline-block;
    text-align: center;
  }
  @media screen and (max-width:992px) {
    .headerMain h1 {
      font-size: 80px;
    }
    .mediaSec .content {
      max-width: 100%;
    }
    .eduSec .degRow {
      flex-direction: column;
    }
    .profSumarySec .sumaryCard {
      text-align: center;
    }
  }
  @media screen and (max-width:768px) {
    .careerHighlights .imgMain.img1 {
      margin: auto;
    }
    .careerHighlights .imgMain {
      margin: auto;
    }
    .careerHighlights .executive {
      margin-top: 30px;
    }
    .awardSec img {
      margin-top: 40px;
    }
  }
  @media screen and (max-width:576px) {
    .headerMain h1 {
      font-size: 45px;
    }
    .readyTo {
      flex-direction: column;
    }
    .readyTo h1 {
      font-size: 35px;
    }
  }